<template>
  <div
    class="page-box"
  >
    <!-- top -->
    <div class="top-box">
      <div class="user-box">
        <img
          :src="user.img"
          alt=""
        >
      </div>
      <div class="top-name">
        {{ user.nickName }}
      </div>
    </div>
    <!-- top end -->

    <!-- 订单 -->
    <div
      class="order-box box"
      style="margin-top: 12px;"
      @click="goOrder(0)"
    >
      <div class="text-a">
        全部订单
      </div>
      <div class="text-b">
        共{{ countObj.allCount || 0 }}课
      </div>
      <div
        class="text-c"
        style="background: #4A77FF;"
      >
        查看
      </div>
    </div>

    <div
      class="order-box box"
      @click="goOrder(1)"
    >
      <div class="text-a">
        未结束订单
      </div>
      <div class="text-b">
        共{{ countObj.unFinishCount || 0 }}课
      </div>
      <div
        class="text-c"
        style="background: #FF7E00;"
      >
        查看
      </div>
    </div>

    <div
      class="order-box box"
      @click="goOrder(2)"
    >
      <div class="text-a">
        已结束订单
      </div>
      <div class="text-b">
        共{{ countObj.finishCount || 0 }}课
      </div>
      <div
        class="text-c"
        style="background: #CFD0E8;"
      >
        查看
      </div>
    </div>

    <div
      class="order-box box"
      @click="goOrder(3)"
    >
      <div class="text-a">
        退课中订单
      </div>
      <div class="text-b">
        共{{ countObj.refundingCount || 0 }}课
      </div>
      <div
        class="text-c"
        style="background: #CFD0E8;"
      >
        查看
      </div>
    </div>

    <div
      class="order-box box"
      @click="goOrder(4)"
    >
      <div class="text-a">
        退课订单
      </div>
      <div class="text-b">
        共{{ countObj.refundCount || 0 }}课
      </div>
      <div
        class="text-c"
        style="background: #CFD0E8;"
      >
        查看
      </div>
    </div>
    <tabbar :active-index="2"></tabbar>
  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar.vue'
export default {
  name: 'UserIndex',
  components: {
    tabbar
  },
  data () {
    return {
      isGlobalPage: false,
      activeNames: ['1'],
      user: {},
      countObj: {}
    }
  },
  computed: {
  },
  created () {
    this.getUserInfo()
    this.getorderCount()
  },
  methods: {
    async getorderCount () {
      let res = await this.$http.userApi.userOrderCount({})
      console.log(res, 'res')
      if (res.code == 200) {
        this.countObj = res.msg || {}
      }
    },
    async getUserInfo () {
      let data = {}
      let res = await this.$http.userApi.userInfo(data)
      console.log(res, 'user')
      if (res.code === 200) {
        this.user = res.msg || {}
      }
    },
    tipFun() {
      this.$toast('暂未开通，敬请期待')
    },
    goOrder (msg) {
      this.$router.push('/order/index?status=' + msg)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #fff;
  min-height: 100%;
  padding-bottom: 60px;
  .top-box {
    height: 166px;
    background: url('~@/assets/img/user/my-logo.png') center center no-repeat;
    background-size: 100% 198px;
    padding-top: 88px;
    padding-left: 15px;
    position: relative;
    .user-box {
      width: 64px;
      height: 64px;
      position: absolute;
      left: 15px;
      bottom: 56px;
      background: #ccc;
      border-radius: 50%;
      overflow: hidden;
      .img-box {
        width: 45px;
        height: 45px;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        /deep/ .van-image {
          height: 100%;
          width: 100%;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .top-name {
      width: 230px;
      line-height: 22px;
      position: absolute;
      left: 99px;
      top: 77px;
      font-size: 21px;
      color: white;
    }
  }
  .order-box {
    background: #fff;
    width: 345px;
    height: 95px;
    margin: 0 auto 15px;
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    .text-a {
      position: absolute;
      left: 27px;
      top: 20px;
      line-height: 25px;
      color: #3D4258;
      font-weight: 600;
      font-size: 18px;
    }
    .text-b {
      color: #8F92A9;
      font-size: 13px;
      line-height: 19px;
      position: absolute;
      left: 27px;
      bottom: 26px;
    }
    .text-c {
      position: absolute;
      right: 29px;
      bottom: 32px;
      line-height: 30px;
      width: 88px;
      border-radius: 15px;
      font-size: 13px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
